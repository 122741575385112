<script>
  export let message;
  export let type;

  import { FormStatuses } from '../constants';
</script>

<style>
  .alert {
    padding: 20px;
    border-radius: 4px;
    background-color: rgb(205, 227, 255);
    color: rgb(35, 56, 112);
    border: 1px solid rgb(63, 82, 133);
  }
  .alert.success {
    background-color: rgb(210, 255, 193);
    color: rgb(51, 141, 15);
    border: 1px solid rgb(51, 141, 15);
  }
  .alert.danger {
    background-color: rgb(255, 231, 231);
    color: rgb(139, 23, 23);
    border: 1px solid rgb(139, 23, 23);
  }
</style>

<div
  class="alert"
  class:success={type === FormStatuses.SUCCESS}
  class:danger={type === FormStatuses.DANGER}>
   {message}
  <slot />
</div>
