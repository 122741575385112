<script>
  import imask from '../utils/useMask';

  export let id;
  export let value;
  export let checked;
  export let label = '';
  export let type = 'text';
  export let onBlur;
  export let onChange;
  export let error;
  export let required;
  export let disabled;
  export let placeholder = '';
  export let prefix;
  export let suffix;
  export let mask;
  export let touched;

  $: invalid = !!error;

  let maskInstance;

  function handleChange(e) {
    value = e.target.value;

    if (onChange) {
      onChange(e);
    }
  }

  function handleAccept(e) {
    value = e.target.value;

    if (onChange) {
      onChange(e);
    }
  }

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e);
    }
  }
</script>

<style>
  .field {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .input-container {
    display: flex;
    justify-content: flex-start;
  }
  .addon {
    background-color: rgb(240, 240, 240);
    color: #414042;
    border: 1px solid #cacaca;
    padding: 10px 18px;
  }
  .prefix {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .suffix {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 10px;
  }
  .input {
    border: 1px solid #cacaca;
    border-radius: 4px;
    padding: 10px 18px;
    font-size: 16px;
    font-family: inherit;
    width: 100%;
    min-width: 33px;
  }

  .radio,
  .checkbox {
    position: absolute;
    display: none;
  }

  .radio[disabled],
  .checkbox[disabled] {
    cursor: not-allowed;
  }

  .radio + label,
  .checkbox + label {
    position: relative;
    display: block;
    padding-left: 30px;
    cursor: pointer;
    vertical-align: middle;
  }
  .radio + label:hover:before,
  .checkbox + label:hover:before {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: hover-color;
  }
  .radio + label:before,
  .checkbox + label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid #c0c0c0;
  }
  .radio + label:after,
  .checkbox + label:after {
    position: absolute;
    display: none;
    content: '';
  }

  .radio[disabled] + label,
  .checkbox[disabled] + label {
    cursor: not-allowed;
    color: #e4e4e4;
  }
  .radio[disabled] + label:hover,
  .radio[disabled] + label:before,
  .radio[disabled] + label:after,
  .checkbox[disabled] + label:hover,
  .checkbox[disabled] + label:before,
  .checkbox[disabled] + label:after {
    cursor: not-allowed;
  }
  .radio[disabled] + label:hover:before,
  .checkbox[disabled] + label:hover:before {
    border: 1px solid #e4e4e4;
    animation-name: none;
  }
  .radio[disabled] + label:before,
  .checkbox[disabled] + label:before {
    border-color: #e4e4e4;
  }
  .radio:checked + label:before,
  .checkbox:checked + label:before {
    animation-name: none;
  }
  .radio:checked + label:after,
  .checkbox:checked + label:after {
    display: block;
  }
  .radio + label:before {
    border-radius: 50%;
  }
  .radio + label:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3e97eb;
  }
  .radio:checked + label:before {
    border: 1px solid #3e97eb;
  }
  .radio:checked[disabled] + label:before {
    border: 1px solid #c9e2f9;
  }
  .radio:checked[disabled] + label:after {
    background: #c9e2f9;
  }

  .checkbox + label:before {
    border-radius: 3px;
  }

  .checkbox + label:after {
    top: 2px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
  }

  .checkbox:checked + label:before {
    border: #f57224;
    background: #f57224;
  }

  .checkbox:checked[disabled] + label:before {
    border: #e2b195;
    background: #e2b195;
  }

  input::placeholder,
  textarea::placeholder {
    color: #414042;
  }
  input:focus,
  textarea:focus {
    border-color: #f57224;
    box-shadow: 0 0 2px #f57224;
  }
  .input:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  .input:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  .input:invalid {
    box-shadow: 0 0 2px hsl(348, 100%, 61%);
  }
  .input.pristine:required:invalid {
    box-shadow: none;
  }
  textarea {
    height: 6em;
  }
  .label-column {
    text-align: right;
    padding-top: 10px;
    padding-right: 12px;
  }
  label {
    font-size: 16px;
    opacity: 0.8;
    color: #414042;
  }
  label:after {
    content: ':';
    margin: 0 0 0 2px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
  select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);

    background-position: calc(100% - 20px) calc(1.2em + 2px),
      calc(100% - 15px) calc(1.2em + 2px), calc(100% - 2.5em) 0.5em;

    background-size: 5px 5px, 5px 5px, 1px 1.8em;

    background-repeat: no-repeat;
  }
  select:required:invalid {
    color: gray;
  }
  :global(option[value=''][disabled]) {
    display: none;
  }
  :global(option) {
    color: black;
  }
  .error {
    margin-top: 4px;
    color: hsl(348, 100%, 61%);
  }

  input::placeholder {
    opacity: 0.3;
  }

  @media (max-width: 600px) {
    label {
      text-align: left;
      margin-bottom: 8px;
    }
  }
</style>

<div class="field grid">
  <div class="col-3 label-column">
    {#if type !== 'checkbox'}
      <label {id} for={id}>
        {@html label}
      </label>
    {/if}
  </div>
  <div class="col-6">
    <div class="input-container">
      {#if prefix}
        <div class="addon prefix">{prefix}</div>
      {/if}
      {#if type === 'select'}
        <select
          bind:value
          {id}
          {placeholder}
          {required}
          {invalid}
          name={id}
          class:pristine={!touched}
          class="input"
          on:change={handleChange}
          on:blur={handleBlur}>
          <slot />
        </select>
      {:else if type === 'checkbox'}
        <input
          bind:checked
          type="checkbox"
          {id}
          {placeholder}
          {required}
          {disabled}
          {invalid}
          name={id}
          class="checkbox" />
        <label for={id}>
          {@html label}
        </label>
      {:else if type === 'radio'}
        <input
          bind:value
          type="radio"
          {id}
          {placeholder}
          {required}
          {disabled}
          {invalid}
          name={id}
          class="radio" />
        <label for={id}>
          {@html label}
        </label>
      {:else if type === 'textarea'}
        <textarea
          bind:value
          {id}
          {placeholder}
          {required}
          {disabled}
          {invalid}
          name={id}
          class:pristine={!touched}
          on:blur={handleBlur}
          class="input"
          use:imask={mask} />
      {:else}
        <input
          {value}
          {id}
          {type}
          {placeholder}
          {required}
          {disabled}
          {invalid}
          class:pristine={!touched}
          on:blur={handleBlur}
          on:input={mask ? undefined : handleChange}
          on:accept={mask ? handleChange : undefined}
          name={id}
          class="input"
          use:imask={mask} />
      {/if}
      {#if suffix}
        <div class="addon suffix">{suffix}</div>
      {/if}
    </div>
    {#if error}
      <div class="error">{error}</div>
    {/if}
  </div>
</div>

