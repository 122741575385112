import IMask from 'imask';

function fireEvent(el, eventName, data) {
  let e = document.createEvent('CustomEvent');
  e.initCustomEvent(eventName, true, true, data);
  el.dispatchEvent(e);
}

export default function (node, options) {
  if (!options) return;

  let mask;

  function initMask(maskOptions) {
    mask = new IMask(node, maskOptions)
      .on('accept', () => {
        fireEvent(node, 'accept', mask);
      })
      .on('complete', () => {
        fireEvent(node, 'complete', mask);
      });
  }

  initMask(options);

  return {
    update(updateOptions) {
      if (updateOptions) {
        if (mask) mask.updateOptions(updateOptions);
        else initMask(updateOptions);
      } else {
        mask.destroy();
      }
    },
    destroy() {
      mask.destroy();
    },
  };
}
